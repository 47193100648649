import React from "react";
import { Button, Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Logo from "../../asserts/Qlik-Logo_RGB.jpg";

import LoginButton from "../LoginButton";
import LogoutButton from "../LogoutButton";
import "./Header.css";

const Header = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <>
    <meta name="robots" content="noindex,nofollow" />
    <div className="header-wraper">
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        className="nav-bgcolor"
      >
        <Container>
          <Navbar.Brand
            className="link-col"
            href="https://status.qlikcloud.com/"
          >
            <img
              src="https://res.cloudinary.com/talend/image/upload/q_auto/v1700581361/qlik/testing/glossary/Qlik_Logo_Trademark_2_Color_Positive_RGB_2_itwa1t_cibcft.svg"
              alt="logo"
              // src="https://www.qlik.com/us/-/media/images/qlik/global/qlik-logo-2x.png"
             height="30" 
              style={{'margin-bottom': '5px'}} 
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                className="link-col"
                href="https://www.qlik.com/us/products"
              >
                Products
              </Nav.Link>
              <Nav.Link
                className="link-col"
                href="https://www.qlik.com/us/solutions"
              >
                Solutions
              </Nav.Link>
              <Nav.Link
                className="link-col"
                href="https://www.qlik.com/us/services/training"
              >
                Learn
              </Nav.Link>
              {/* <NavDropdown title="Learn" id="collasible-nav-dropdown">
                <NavDropdown.Item href="https://www.qlik.com/us/services/training">
                  My Learning
                </NavDropdown.Item>
              </NavDropdown> */}
              <Nav.Link
                className="link-col"
                href="https://www.qlik.com/us/services"
              >
                Support
              </Nav.Link>
              <Nav.Link
                className="link-col"
                href="https://www.qlik.com/us/company"
              >
                Company
              </Nav.Link>
            </Nav>

            <Nav className="customButton">
              {!isAuthenticated && <LoginButton />}
              {isAuthenticated && <LogoutButton />}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  </>
  );
};

export default Header;
